import { Tab, Tabs } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { UserIcon } from "src/components/organisms/user_icon/user_icon";
import { headerIndexState } from "src/store/recoil_state";

const fontStyle = {
  fontFamily: "Yuji Syuku, serif",
  fontSize: "1.2rem",
};

export const Header = () => {
  const [headerIndex, setHeaderIndex] = useRecoilState(headerIndexState);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setHeaderIndex(newValue);
    },
    [setHeaderIndex]
  );
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", padding: "10px" }}>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={headerIndex}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab
          style={fontStyle}
          label="ほおむ"
          onClick={() => {
            navigate("");
          }}
        />
        <Tab
          style={fontStyle}
          label="すこああたっく"
          onClick={() => {
            navigate("/score-attack");
          }}
        />
        <Tab
          style={fontStyle}
          label="一覧"
          onClick={() => {
            navigate("/list");
          }}
        />
      </Tabs>
      <UserIcon />
    </div>
  );
};
