import { useNavigate } from "react-router-dom";
import { GoogleAuthButton } from "src/components/molecules/auth_button/google_auth_button";
import { useNotifier } from "src/components/organisms/context_snackbar/context_snackbar";
import { loginWithGoogle } from "src/firebase/auth/auth";
import { registerAccount } from "src/firebase/store/firestore";

export const SignUp = (props: { token: string }) => {
  const navigate = useNavigate();
  const notifier = useNotifier();
  return (
    <>
      <GoogleAuthButton
        type="signup"
        onClick={() => {
          loginWithGoogle()
            .then((register) => {
              navigate("/");
              registerAccount(props.token, register.user.uid);
            })
            .catch((error) => {
              notifier.notifyError(error.message);
            });
        }}
      />
    </>
  );
};
