import { Button } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { useRecoilValue } from "recoil";
import { loginSwitch } from "src/store/recoil_state";

export const SignOut = () => {
  const user = useRecoilValue(loginSwitch);
  return (
    <div style={{ width: "100%", marginTop: "30px" }}>
      <Button
        onClick={() => {
          const auth = getAuth();
          signOut(auth)
            .then(() => {
              console.log("Sign out");
            })
            .catch((error) => {
              console.error("Error signing out:", error);
            });
        }}
        variant="outlined"
        color="secondary"
        fullWidth
        disabled={!user}
        style={{
          textTransform: "none",
        }}
      >
        Sign out
      </Button>
    </div>
  );
};
