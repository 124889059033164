import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ProblemID } from "src/core/problem";

export const AnswerForm = (props: {
  problemID?: ProblemID;
  foodOrDrink: "food" | "drink";
  onAnswer: (
    answer: string,
    problemID: ProblemID,
    setAnswer: (answer: string | undefined) => void,
    setProblemID: (problemID: ProblemID | undefined) => void
  ) => void;
}) => {
  const [problemID, setProblemID] = useState<ProblemID | undefined>(
    props.problemID
  );
  const [answer, setAnswer] = useState<string | undefined>();

  useEffect(() => {
    setProblemID(props.problemID);
  }, [props.problemID]);

  return (
    <>
      <Box
        sx={{
          // p: 3,
          marginTop: 2,
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {props.problemID === undefined ? (
          <TextField
            sx={{ maxWidth: "80%", m: 1 }}
            id="outlined-basic"
            label="問題番号"
            variant="outlined"
            type="number"
            disabled={props.problemID !== undefined}
            onChange={(e) => {
              console.log(e.target.value, Number(e.target.value));
              if (e.target.value === "") {
                setProblemID(undefined);
              } else {
                setProblemID(Number(e.target.value));
              }
            }}
            value={problemID === undefined ? "" : problemID}
          />
        ) : (
          <></>
        )}
        <TextField
          id="outlined-basic"
          sx={{ maxWidth: "80%", m: 1 }}
          label="答え"
          placeholder="ひらがなで入力"
          variant="outlined"
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
          value={answer === undefined ? "" : answer}
        />
        <Button
          variant="outlined"
          style={{ width: "100px" }}
          sx={{ m: 1 }}
          disabled={problemID === undefined || answer === undefined}
          onClick={() => {
            if (answer !== undefined) {
              if (props.problemID !== undefined) {
                props.onAnswer(
                  answer,
                  props.problemID,
                  setAnswer,
                  setProblemID
                );
              } else {
                if (problemID !== undefined) {
                  props.onAnswer(answer, problemID, setAnswer, setProblemID);
                }
              }
            }
          }}
        >
          おかわり
        </Button>
      </Box>
    </>
  );
};
