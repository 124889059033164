/**
 * type definition and static data about order.
 */

import { useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";
/**
 * sloppy status.
 */
export type ProblemStatus = "unsolved" | "solved" | "serving" | "skipped";

/**
 * problem meta category.
 * onlyPerformance: can solve only in performance.
 * anywhere: can solve anywhere.
 */
export type ProblemMetaCategory = "onlyPerformance" | "anywhere";

export type ProblemID = number;

export type MenuCategory =
  | "strong-alcohol"
  | "weak-alcohol"
  | "food"
  | "speed"
  | "recommend"
  | "dessert"
  | "finish";

/**
 * problem dynamic data in realtime database.
 */
export type RealtimeProblem = {
  status: ProblemStatus;
  id: number;
};

export type ProblemDefinition = {
  answer: string[];
  category: MenuCategory;
  id: ProblemID;
  status: ProblemStatus;
  score: number;
  problemMetaCategory?: ProblemMetaCategory;
  alcoholLevel: number;
  explanation: string
};

/**
 * cookie key for problem.
 */
export const PROBLEM_COOKIE_KEY = "problem";

/**
 * cookie type for problem.
 */
export type ProblemCookie = {
  status: ProblemStatus;
};

/**
 * naive useCookie wrapper for type hinting.
 */
export const useProblemCookie = (
  problemID: ProblemID
): [
  ProblemCookie,
  (value: ProblemCookie, options?: CookieSetOptions) => void,
  any,
  any,
] => {
  const dependency = useMemo(() => {
    return [`${PROBLEM_COOKIE_KEY}-${problemID}`];
  }, [problemID]);

  const [cookies, setCookie, removeCookie, updateCookies] =
    useCookies(dependency);

  const problemCookie = cookies[`${PROBLEM_COOKIE_KEY}-${problemID}`];
  const setProblemCookie = useCallback(
    (value: ProblemCookie, options?: CookieSetOptions) => {
      setCookie(`${PROBLEM_COOKIE_KEY}-${problemID}`, value, options);
    },
    [problemID, setCookie]
  );
  return [problemCookie, setProblemCookie, removeCookie, updateCookies];
};

export const deleteAllProblemCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    if (name.includes(PROBLEM_COOKIE_KEY)) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }
};
