import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { AuthTemplate } from "src/components/templates/auth_template/auth_template";
import { Header } from "src/components/templates/header/header";

/**
 * for react-router.
 */
export const HeaderTemplate = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ flexGrow: 0, height: "auto" }}>
        <Header />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto", height: "0px" }}>
        <AuthTemplate>
          <Outlet />
        </AuthTemplate>
      </Box>
    </Box>
  );
};
