import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { GoogleAuthButton } from "src/components/molecules/auth_button/google_auth_button";
import { useNotifier } from "src/components/organisms/context_snackbar/context_snackbar";
import { loginWithGoogle } from "src/firebase/auth/auth";
import { existsAccount } from "src/firebase/store/firestore";

export const SignIn = () => {
  const navigate = useNavigate();
  const notifier = useNotifier();
  return (
    <>
      <GoogleAuthButton
        type="signin"
        onClick={() => {
          loginWithGoogle()
            .then((credential) => {
              existsAccount(credential.user.uid).then((exist) => {
                if (exist) {
                  navigate("/");
                } else {
                  notifier.notifyError("このアカウントは登録されていません。");
                  const auth = getAuth();
                  signOut(auth)
                    .then(() => {
                      console.log("Sign out");
                    })
                    .catch((error) => {
                      console.error("Error signing out:", error);
                    });
                }
              });
            })
            .catch((error) => {
              notifier.notifyError(error.message);
            });
        }}
      />
    </>
  );
};
