import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { GameFooter } from "src/components/organisms/game_footer/game_footer";
import { AuthTemplate } from "src/components/templates/auth_template/auth_template";
import { GameHeader } from "src/components/templates/game_header/game_header";

/**
 * for react-router.
 */
export const GameHeaderTemplate = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ flexGrow: 0, height: "auto" }}>
        <GameHeader />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto", height: "0px" }}>
        <AuthTemplate>
          <Outlet />
        </AuthTemplate>
      </Box>
      <Box sx={{ flexGrow: 0, height: "auto" }}>
        {/* only for spacing */}
        <Box sx={{ height: "10vh" }}></Box>
        <GameFooter height={"10vh"} />
      </Box>
    </Box>
  );
};
