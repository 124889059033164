import { Box, Fade } from "@mui/material";
import { useMemo } from "react";
import { ProblemPopupButton } from "src/components/pages/main/problem_popup_button";
import { MenuCategory, ProblemDefinition, ProblemID } from "src/core/problem";
import { DrunkennessDegree } from "src/core/type";
import { PROBLEMS } from "src/data/problem_data";

const isDrink = (problemID: ProblemID) => {
  return problemID >= 500;
};

const naiveDrunkenness: DrunkennessDegree[] = [0, 1, 2, 3];

export const ListPage = () => {
  const problemByCategory = useMemo(
    () => aggregateProblemByCategory(PROBLEMS),
    []
  );
  return (
    <Fade in={true} style={{ width: "100%", height: "100%" }} timeout={1000}>
      <Box
        style={{
          height: "100%",
          width: "100%",
          // backgroundImage: "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)), url(/images/background.jpg)",
          // backgroundImage: "url(/images/background.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          // backdropFilter: "blur(30px)",
          // WebkitBackdropFilter: "blur(30px)",
          backgroundAttachment: "fixed",
        }}
      >
        {" "}
        <Box className="space-5">
          <Box>
            <ProblemPopupButton
              problemID={0}
              canPopup={false}
              status="unsolved"
            />
            - 未回答の問題
          </Box>
          <Box>
            <ProblemPopupButton
              problemID={0}
              canPopup={false}
              status="solved"
            />
            - 回答済みの問題
          </Box>
          <Box>
            <ProblemPopupButton
              problemID={0}
              canPopup={false}
              problemMetaCategory="onlyPerformance"
            />
            - 公演中のみ解答可能な問題
          </Box>
        </Box>
        {Object.keys(problemByCategory).map((category) => {
          return (
            <div
              key={category}
              style={{
                marginBottom: "10px",
                paddingLeft: "2vw",
                paddingRight: "2vw",
              }}
            >
              <h2
                style={{
                  fontFamily: "Yuji Syuku, serif",
                  fontSize: "1.3rem",
                  fontWeight: "normal",
                  // WebkitTextStroke: "0.2px #FFF",
                  // textStro: 1px #FFF;
                }}
              >
                {categoryToJapanese[category as MenuCategory]}
              </h2>
              {problemByCategory[category].map((problem) => {
                if (isDrink(problem.id)) {
                  return (
                    <Box key={problem.id}>
                      {naiveDrunkenness.map((degree) => {
                        return (
                          <ProblemPopupButton
                            problemID={problem.id}
                            key={`${problem.id}-${degree}`}
                            canPopup={true}
                            drunkennessDegree={degree}
                          />
                        );
                      })}
                    </Box>
                  );
                } else {
                  return (
                    <ProblemPopupButton
                      problemID={problem.id}
                      key={problem.id}
                      canPopup={true}
                      problemMetaCategory={problem.problemMetaCategory}
                    />
                  );
                }
              })}
            </div>
          );
        })}
      </Box>
    </Fade>
  );
};

const aggregateProblemByCategory = (problems: typeof PROBLEMS) => {
  const result: { [key: string]: ProblemDefinition[] } = {};
  problems.forEach((problem) => {
    if (result[problem.category]) {
      result[problem.category].push({ ...problem, status: "unsolved" });
    } else {
      result[problem.category] = [{ ...problem, status: "unsolved" }];
    }
  });
  return result;
};

const categoryToJapanese: { [category in MenuCategory]: string } = {
  food: "メイン料理",
  "strong-alcohol": "強めのお酒",
  speed: "スピードメニュー",
  recommend: "おすすめ",
  finish: "〆の逸品",
  dessert: "デザート",
  "weak-alcohol": "弱めのお酒",
};
