import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { rgba } from "polished";
import { CSSProperties, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { BaseImage } from "src/components/atoms/image/image";
import { useRemainingGameTime } from "src/components/organisms/use_gametime/use_gametime";
import { convertToDrunkennessDegree } from "src/core/converter";
import { DrunkennessDegree } from "src/core/type";
import { gameState } from "src/store/recoil_state";

const color = "white";

export type GameFooterProps = {
  height: string;
};
export const GameFooter = (props: GameFooterProps) => {
  const gameData = useRecoilValue(gameState);
  const [remainingMinutes, remainingSeconds] = useRemainingGameTime(
    dayjs(gameData.config?.startAt),
    gameData.config?.gameTimeMinutes
  );
  const paddedSecond = String(remainingSeconds).padStart(2, "0");

  const paddedMinutes = String(remainingMinutes).padStart(2, "0");

  const remainingTimeText = useMemo(() => {
    if (gameData.status?.progress === "waiting") {
      return `残り ${gameData.config?.gameTimeMinutes}:00`;
    }
    if (paddedMinutes === "undefined" || paddedSecond === "undefined")
      return "ラストオーダー終了";
    return `残り ${paddedMinutes}:${paddedSecond}`;
  }, [
    paddedMinutes,
    paddedSecond,
    gameData.config?.gameTimeMinutes,
    gameData.status?.progress,
  ]);

  const drunkennessDegree = convertToDrunkennessDegree(
    gameData.status?.drunkennessLevel ?? 0
  );

  const theme = useTheme();
  // useEffect(() => {
  //   console.log("update", statusData);
  // }, [statusData]);
  return (
    <div
      style={{
        flexGrow: 0,
        height: props.height,
        // maxHeight: "60px",
        backgroundColor: rgba(theme.palette.primary.main, 0.8),
        color: color,
        position: "fixed",
        bottom: "0",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          flexGrow: 0,
          height: "inherit",
          // maxHeight: "60px",
          position: "absolute",
          // backgroundColor: rgba(theme.palette.primary.dark, 0.2),
          // position: "fixed",
          width: "100%",
          // bottom: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
            // backgroundColor: "blue",
            width: "10vh",
            borderRadius: "50%",
            borderWidth: "2px",
            borderColor: color,
            borderStyle: "solid",
          }}
        >
          <Score score={gameData.status?.score ?? 0} />
        </div>
      </div>

      <div
        style={{
          flexGrow: 0,
          height: props.height,
          // backgroundColor: rgba(theme.palette.secondary.main, 0.2),
          // position: "fixed",
          width: "100%",
          // bottom: "0",
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "20vw",
            maxWidth: "10vh",
            marginLeft: "2vw",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          <DrunkennessImage drunkennessDegree={drunkennessDegree} />
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "2vw",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          {remainingTimeText}
        </div>
      </div>
    </div>
  );
};

export const scoreStyle: CSSProperties = {
  fontFamily: "Yuji Syuku, serif",
  letterSpacing: "-0.15em",
  textIndent: "-0.15em",
  paddingBottom: "1vh",
  // fontWeight: 400,
  fontStyle: "normal",
  fontSize: "7vh", // because footer height is 10vh.
  width: "100%",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  height: "100%",
};

const Score = (props: { score: number }) => {
  return (
    <div style={{ ...scoreStyle }}>
      {props.score}
      {/* 25 */}
    </div>
  );
};

const DrunkennessImage = (props: { drunkennessDegree: DrunkennessDegree }) => {
  const imagePath = useMemo(() => {
    return `/images/drunkenness/drunkenness_${props.drunkennessDegree}.png`;
  }, [props.drunkennessDegree]);
  return <BaseImage src={imagePath} width="100%" aspectRatio="1" />;
};
