import { Button } from "@mui/material";
import { useMemo } from "react";
import { useModal } from "src/components/organisms/context_modal/context_modal";
import { Problem } from "src/components/pages/main/propblem";
import {
  ProblemID,
  ProblemMetaCategory,
  ProblemStatus,
  useProblemCookie,
} from "src/core/problem";
import { DrunkennessDegree } from "src/core/type";

export type ProblemPopupButtonProps = {
  problemID: ProblemID;
  canPopup: boolean;
  /**
   * only for usage in main page... stupid.
   */
  status?: ProblemStatus;
  /**
   * only for usage in main page... stupid.
   */
  problemMetaCategory?: ProblemMetaCategory;

  drunkennessDegree?: DrunkennessDegree;
};

export const ProblemPopupButton = (props: ProblemPopupButtonProps) => {
  const modalController = useModal();
  const [cookies] = useProblemCookie(props.problemID);

  const variant = useMemo(() => {
    if (props.status) {
      return props.status === "solved" ? "outlined" : "contained";
    } else {
      return cookies?.status === "solved" ? "outlined" : "contained";
    }
  }, [cookies?.status, props.status]);
  const color = useMemo(() => {
    if (props.problemMetaCategory) {
      return props.problemMetaCategory === "onlyPerformance"
        ? "info"
        : "primary";
    } else {
      return "primary";
    }
  }, [props.problemMetaCategory]);

  const imagePath = useMemo(() => {
    const paddedOrderDrinkID = String(props.problemID).padStart(3, "0");
    if (props.drunkennessDegree === undefined) {
      return `/images/nazo/nazo_${paddedOrderDrinkID}.png`;
    } else {
      return `/images/nazo/nazo_${paddedOrderDrinkID}-${props.drunkennessDegree}.png`;
    }
  }, [props.drunkennessDegree, props.problemID]);

  const text = useMemo(() => {
    if(props.drunkennessDegree === undefined){

      return `${props.problemID}`
    }
    else{
      return `${props.problemID}-${props.drunkennessDegree}`
    }
  }, [props.drunkennessDegree, props.problemID])

  return (
    <Button
      color={color}
      variant={variant}
      style={{ margin: "2px" }}
      onClick={() => {
        if (props.canPopup) {
          modalController.open(
            <Problem imagePath={imagePath} problemID={props.problemID} />
          );
        }
      }}
      key={props.problemID}
    >
      {text}
    </Button>
  );
};
