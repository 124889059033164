import { DrunkennessDegree } from "src/core/type";

export const convertToDrunkennessDegree = (
  drunkennessLevel: number
): DrunkennessDegree => {
  const quotient = Math.floor(drunkennessLevel / 40);
  if (quotient >= 3) {
    return 3;
  } else if (quotient <= 0) {
    return 0;
  } else {
    // * ensure safety logically.
    return quotient as DrunkennessDegree;
  }
};
