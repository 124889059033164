import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useInterval } from "src/util/use_interval";

/**
 * only for interpretability.
 */
type RemainingMinutes = number | undefined;
type RemainingSeconds = number | undefined;
type Expired = boolean | undefined;

/**
 * update the remaining time of the game.
 */
export const useRemainingGameTime = (
  startTime?: Dayjs,
  gameTimeMinutes?: number
): [RemainingMinutes, RemainingSeconds, Expired] => {
  const [currentTime, setCurrentTime] = useState(dayjs());

  useInterval(() => {
    setCurrentTime(dayjs());
  }, 1000);

  if (startTime === undefined) {
    return [undefined, undefined, undefined];
  }

  if (gameTimeMinutes === undefined) {
    return [undefined, undefined, undefined];
  } else {
    const endTime = startTime.add(gameTimeMinutes ?? 999, "minute");
    if (endTime < currentTime) {
      return [undefined, undefined, true];
    } else {
      const differenceSeconds = endTime.diff(currentTime, "second");
      const differenceSecond = differenceSeconds % 60;

      const differenceMinutes = Math.floor(differenceSeconds / 60);
      const differenceMinute = differenceMinutes % 60;
      return [differenceMinute, differenceSecond, false];
    }
  }
};
