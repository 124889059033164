import { useEffect, useRef } from "react";

export const useInterval = (callback: () => void, delayMS?: number) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delayMS === undefined) {
      return;
    }
    const id = setInterval(() => {
      savedCallback.current?.();
    }, delayMS);
    return () => clearInterval(id);
  }, [delayMS]);
};
