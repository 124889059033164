import { Box, Button, Fade } from "@mui/material";

export const ScoreAttackPage = () => {
  return (
    <Fade in={true} style={{ width: "100%", height: "100%" }} timeout={1000}>
      <Box
        width={"100%"}
        height={"100%"}
        p={2}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        className="space-10"
      >
        {/* <p>
          すこああたっくは、15分の制限時間内に何杯のお酒を飲めるかを競うモードです。
        </p>
        <p>
          お酒だけを飲み進めると酔いが回り、問題を解くことが難しくなります。
        </p>
        <p>
          適度に食事も取りつつお酒を楽しむことで、より多くの問題を解くことができるでしょう。
        </p>
        <p>すこああたっくを始めますか？</p> */}

        <p>すみません、間に合いませんでした！！！近日中に公開します...</p>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            window.open("/game", "_blank");
          }}
          disabled
        >
          開始する(新しいタブが開きます)
        </Button>
      </Box>
    </Fade>
  );
};
