import { Box, Button } from "@mui/material";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { BaseImage } from "src/components/atoms/image/image";
import { AnswerForm } from "src/components/organisms/answer_form/answer_form";
import { useNotifier } from "src/components/organisms/context_snackbar/context_snackbar";
import { convertToDrunkennessDegree } from "src/core/converter";
import { useDrinkUpAlcohol, useOrderAlcohol } from "src/core/game_hooks";
import { PROBLEMS } from "src/data/problem_data";
import { gameState } from "src/store/recoil_state";

export const GameDrinkPage = () => {
  const gameData = useRecoilValue(gameState);
  const notifier = useNotifier();
  const orderAlcohol = useOrderAlcohol();
  const drinkUpAlcohol = useDrinkUpAlcohol();

  const gameOrderData = useMemo(() => {
    console.log("gameData", gameData, gameData.order);
    return gameData.order[0];
  }, [gameData]);

  const orderedDrinkID = useMemo(() => {
    return gameOrderData.alcohol;
  }, [gameOrderData.alcohol]);

  const drunkennessDegree = convertToDrunkennessDegree(
    gameData.status.drunkennessLevel ?? 0
  );

  const problemImagePath = useMemo(() => {
    console.log(orderedDrinkID, gameData.status.drunkennessLevel);
    if (
      orderedDrinkID === undefined ||
      gameData.status.drunkennessLevel === undefined
    ) {
      return undefined;
    } else {
      const paddedOrderDrinkID = String(orderedDrinkID).padStart(3, "0");

      return `/images/nazo/nazo_${paddedOrderDrinkID}-${drunkennessDegree}.png`;
    }
  }, [drunkennessDegree, gameData.status.drunkennessLevel, orderedDrinkID]);
  // return <Box padding={2}>{JSON.stringify(gameData)}</Box>;

  if (problemImagePath) {
    return (
      <Box padding={2} height={"100%"}>
        <BaseImage
          src={problemImagePath}
          width="90%"
          maxWidth="600px"
          aspectRatio="1"
        />
        <AnswerForm
          foodOrDrink="drink"
          problemID={orderedDrinkID}
          onAnswer={(answer) => {
            if (orderedDrinkID !== undefined) {
              const targetProblems = PROBLEMS.filter((problem) => {
                return problem.id === orderedDrinkID;
              });
              if (targetProblems.length === 0) {
                notifier.notifyError(
                  "該当の問題が見つかりませんでした...店員を呼んでください。"
                );
              } else {
                const targetProblem = targetProblems[0];
                if (targetProblem.answer.includes(answer)) {
                  notifier.notifySuccess("正解です！");
                  drinkUpAlcohol(0, orderedDrinkID);
                } else {
                  notifier.notifyError("不正解です...");
                }
              }
            }
          }}
        />
      </Box>
    );
  } else {
    return (
      <Box
        padding={2}
        display={"flex"}
        justifyContent={"center"}
        // alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
      >
        <Button
          disabled={orderedDrinkID !== undefined}
          variant="contained"
          sx={{ marginRight: "10px" }}
          onClick={() => {
            try {
              orderAlcohol(0, "weak-alcohol", 0);
            } catch (e) {
              console.log("error", e);
              notifier.notifyError("注文できる酒がありません！");
            }
          }}
        >
          弱アル
        </Button>
        <Button
          disabled={orderedDrinkID !== undefined}
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            const randomIndex = 0;
            try {
              orderAlcohol(0, "strong-alcohol", randomIndex);
            } catch (e) {
              console.log("error", e);
              notifier.notifyError("注文できる酒がありません！");
            }
          }}
        >
          強アル
        </Button>
      </Box>
    );
  }
};
