import { Button } from "@mui/material";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { generateInitialGameState } from "src/core/game";
import { useDrinkUpAlcohol } from "src/core/game_hooks";
import { gameState } from "src/store/recoil_state";

export const GameRulePage = () => {
  const [gameData, setGameData] = useRecoilState(gameState);

  const onClick = useCallback(() => {
    console.log("ゲーム開始");
    setGameData(generateInitialGameState());
  }, [setGameData]);
  return (
    <div>
      <Button onClick={onClick}>ゲーム開始</Button>
    </div>
  );
};
