import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import { NotFoundPage } from "src/components/pages/not_found/not_found_page";
import { SignInPage } from "src/components/pages/login/signin_page";
import { FirebaseUser } from "src/firebase/auth/user";
import { firebaseAuth } from "src/firebase/firebase";
import { loginSwitch } from "src/store/recoil_state";
import { MainPage } from "src/components/pages/main/main_page";
import { HeaderTemplate } from "src/components/templates/header_template/header_template";
import { ScoreAttackPage } from "src/components/pages/score_attack/score_attack_page";
import { ListPage } from "src/components/pages/list/list_page";
import { GameDrinkPage } from "src/components/pages/game/drink/drink_page";
import { GameHeaderTemplate } from "src/components/templates/game_header_template/game_header_template";
import { GameRulePage } from "src/components/pages/game/rule/rule_page";
import { GameFoodPage } from "src/components/pages/game/food/food_page";

export const AppRoute: React.FC = () => {
  const [, setLoginState] = useRecoilState(loginSwitch);
  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      console.log("onAuthStateChanged", user?.toJSON());
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // TODO use type guard.
        setLoginState(user.toJSON() as FirebaseUser);
      } else {
        setLoginState(undefined);
      }
    });
  }, [setLoginState]);

  return (
    <Routes>
      <Route path={""} element={<HeaderTemplate />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/list" element={<ListPage />} />
        <Route path="/score-attack" element={<ScoreAttackPage />} />
      </Route>
      <Route path={"game"} element={<GameHeaderTemplate />}>
        <Route path="rule" element={<GameRulePage />} />
        <Route path="drink" element={<GameDrinkPage />} />
        <Route path="food" element={<GameFoodPage />} />
      </Route>
      <Route path="/login" element={<SignInPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
