import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { firestore } from "src/firebase/firebase";

export const registerAccount = async (hash: string, accountID: string) => {
  const ref = collection(firestore, "accounts");
  const docRef = doc(ref, hash);
  console.log(docRef.id);
  updateDoc(docRef, {
    accountID: accountID,
    createdAt: new Date(),
  });

  return docRef.id;
};

export const existsAccount = async (userID: string): Promise<boolean> => {
  const ref = collection(firestore, "accounts");
  const docRef = await getDocs(ref);
  const exist = docRef.docs.some((doc) => doc.data().accountID === userID);
  return exist;
};
