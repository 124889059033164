import { Box, Modal } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { modalComponent, modalSwitch } from "src/store/recoil_state";

export type ModalContextProps = {
  children: ReactNode;
};
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  // p: 4,
  // boxSizing: "content-box",
};
export const ModalContext = (props: ModalContextProps) => {
  const [isOpen, setIsOpen] = useRecoilState(modalSwitch);
  const component = useRecoilValue(modalComponent);

  const handleClose = () => setIsOpen(false);
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...modalStyle, boxSizing: "border-box"}}>{component}</Box>
        {/* <Box sx={{}}>{component}</Box> */}
      </Modal>
      {props.children}
    </>
  );
};

export const useModal = () => {
  const [, setModalComponent] = useRecoilState(modalComponent);
  const [, setModalSwitch] = useRecoilState(modalSwitch);

  const modalController = useMemo(() => {
    return {
      open: (modalComponent: ReactNode) => {
        setModalComponent(modalComponent);
        setModalSwitch(true);
      },
      close: () => {
        setModalComponent(<></>);
        setModalSwitch(false);
      },
    };
  }, []);
  return modalController;
};
