import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { GameID } from "src/core/game";
import { MenuCategory, ProblemID } from "src/core/problem";
import { PROBLEM_DICT } from "src/data/problem_data";
import { gameState } from "src/store/recoil_state";

type DrinkUpAlcoholFunc = (customerID: number, drinkID: ProblemID) => void;

export const useDrinkUpAlcohol = () => {
  const [, setGameData] = useRecoilState(gameState);

  const drinkUpAlcohol = useCallback<DrinkUpAlcoholFunc>(
    (customerID, drinkID) => {
      const alcoholLevel = PROBLEM_DICT[drinkID].alcoholLevel;
      setGameData((currentGameData) => {
        return {
          config: currentGameData.config,
          order: [
            {
              alcohol: undefined,
            },
          ],
          status: {
            ...currentGameData.status,
            score: currentGameData.status.score + 1,
            drunkennessLevel:
              currentGameData.status.drunkennessLevel + alcoholLevel,
          },
          problem: {
            ...currentGameData.problem,
            [drinkID]: {
              ...currentGameData.problem[drinkID],
              status: "solved",
            },
          },
        };
      });
    },
    [setGameData]
  );
  return drinkUpAlcohol;
};

type OrderAlcoholFunc = (
  customerID: number,
  alcoholType: MenuCategory,
  targetIndex: number
) => void;
export const useOrderAlcohol = () => {
  const [gameData, setGameData] = useRecoilState(gameState);

  const orderAlcohol = useCallback<OrderAlcoholFunc>(
    (customerID, alcoholType, targetIndex) => {
      const targetProblems = Object.keys(gameData.problem).filter((key) => {
        return (
          PROBLEM_DICT[key].category === alcoholType &&
          gameData.problem[key].status === "unsolved"
        );
      });
      if (targetProblems.length === 0) {
        throw new Error("No target problems found");
      }
      const randomIndex = targetIndex % targetProblems.length;
      // alert(`randomIndex ${randomIndex}, ${targetProblems[randomIndex]}`);
      const problemToBeServed = PROBLEM_DICT[targetProblems[randomIndex]];
      console.log("problemToBeServed: ", problemToBeServed);
      setGameData((currentGameData) => {
        const copiedOrder = [...currentGameData.order];
        copiedOrder[customerID] = {
          alcohol: problemToBeServed.id,
        };
        return {
          config: currentGameData.config,
          order: copiedOrder,
          status: currentGameData.status,
          problem: {
            ...currentGameData.problem,
            [problemToBeServed.id]: problemToBeServed,
          },
        };
      });
    },
    [gameData.problem, setGameData]
  );
  return orderAlcohol;
};
