import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { SignIn } from "src/components/pages/login/sign_in";
import { SignOut } from "src/components/pages/login/sign_out";
import { SignUp } from "src/components/pages/login/sign_up";
import { Header } from "src/components/templates/header/header";
import { HASH_DATA, calcHash } from "src/data/hash_data";
import { existsAccount } from "src/firebase/store/firestore";
import { loginSwitch } from "src/store/recoil_state";

const ButtonWrapper = (props: { children: React.ReactNode }) => {
  return (
    <>
      <div
        style={{
          // height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "20%",
            minWidth: "200px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
          className="space-10"
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

const TextWrapper = (props: { children: React.ReactNode }) => {
  return (
    <>
      <div
        style={{
          // height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            width: "80%",
            maxWidth: "500px",
            alignItems: "left",
            display: "flex",
            flexDirection: "column",
          }}
          className="space-10"
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

export const SignInPage = () => {
  const [searchParams] = useSearchParams();
  const token = useMemo(() => searchParams.get("token"), [searchParams]);
  const user = useRecoilValue(loginSwitch);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        existsAccount(user.uid).then((exist) => {
          if (exist) {
            navigate("/");
          } else {
            console.error("This account is not registered.");
          }
        });
      }, 1000);
    }
  }, [user, navigate]);
  if (user) {
    return (
      <>
        <TextWrapper>既にログインしています</TextWrapper>
        <ButtonWrapper>
          <SignOut />
        </ButtonWrapper>
      </>
    );
  }

  if (token) {
    return <TokenAuthPage token={token} />;
  }

  return <NoTokenAuthPage />;
};

const TokenAuthPage = (props: { token: string }) => {
  const isValidToken = useMemo(() => {
    const hash = calcHash(props.token);
    console.log(`hash: ${hash}`);
    return HASH_DATA.includes(hash);
  }, [props.token]);

  return (
    <>
      <Header />
      <Greeting />
      {isValidToken ? (
        <>
          <TextWrapper>
            初めての方はアカウント登録後、テイクアウト商品をお楽しみください。
          </TextWrapper>
          <ButtonWrapper>
            <SignUp token={props.token} />
          </ButtonWrapper>
        </>
      ) : (
        <></>
      )}
      <TextWrapper>既にご登録済みの場合ログインをお願いします。</TextWrapper>
      <ButtonWrapper>
        <SignIn />
      </ButtonWrapper>
      <TextWrapper>
        ご登録済みのアカウントがない場合、QRコードからのアクセスをお試しください。
      </TextWrapper>
      <TextWrapper>
        QRコードで上手くアクセスできない場合は、以下までお問い合わせください。
      </TextWrapper>
      <Contact />
    </>
  );
};

const NoTokenAuthPage = () => {
  return (
    <>
      <Header />
      <Greeting />
      <TextWrapper>
        ご登録済みのアカウントでのログインをお願いします。
      </TextWrapper>
      <ButtonWrapper>
        <SignIn />
        {/* <SignOut /> */}
      </ButtonWrapper>
      <TextWrapper>
        ご登録済みのアカウントがない場合、QRコードからのアクセスをお試しください。
      </TextWrapper>
      <TextWrapper>
        QRコードで上手くアクセスできない場合は、以下までお問い合わせください。
      </TextWrapper>
      <Contact />
    </>
  );
};

const Contact = () => {
  return (
    <TextWrapper>
      問い合わせ先:
      <a href="mailto:killingtime.zounomure@gmail.com?subject=居酒屋桐蔵でのトラブル&body=症状:<こちらにトラブルの内容をお書きください。>">
        きりんぐたいむに象の群れ
      </a>
    </TextWrapper>
  );
};

const Greeting = () => {
  return (
    <TextWrapper>
      居酒屋桐蔵のテイクアウト商品のご購入ありがとうございます！
    </TextWrapper>
  );
};

// const HowToLogin = () => {
//   return (
//     <>
//       <p>ログイン</p>
//     </>
//   );
// };
