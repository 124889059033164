import { ProblemDefinition, ProblemID } from "src/core/problem";

/**
 * problem master data.
 */
export const PROBLEMS: Omit<ProblemDefinition, "status">[] = [
  {
    answer: ["酒盗", "しゅとう", "しゅとー"],
    category: "speed",
    id: 1,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "「PARIS」「LONDON」「TOKYO」はそれぞれ「首都（しゅと）」を表します。それをふまえると、上から「取得（しゅとく）」「シュート（しゅーと）」となり、答えは「酒盗（しゅとう）」になります。",
  },
  {
    answer: ["さんちゅ", "サンチュ"],
    category: "speed",
    id: 2,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図は道路の状況を表していました。それぞれ「左折」「直進」「右折」「Uターン」を表しており、丸数字の順番に読むと答えは「サンチュ」になります。",
  },
  {
    answer: ["梅水晶", "うめすいしょう", "うめすいしょー"],
    category: "speed",
    id: 3,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "それぞれ「松竹梅」「曜日」「（漢字における）日の数」を表します。左から順に、2つグレードを下げて「梅」、2つ曜日を進めて「水」、日を3つにして「晶」で、答えは「梅水晶」になります。",
  },
  {
    answer: ["だしまきたまご", "だし巻き玉子"],
    category: "speed",
    id: 4,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "上の図は卵です。下の図はそれぞれ「たまご」「きみ」「しろみ」を表しており、書かれた数字の順番にある文字を読んでいくと、答えは「だしまきたまご」になります。",
  },
  {
    answer: ["シーザーサラダ", "しーざーさらだ"],
    category: "speed",
    id: 5,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "「皿」の上に「シーサー」「タ」「濁点（゛）2つ」が載っているので答えは「シーザーサラダ」になります。",
  },
  {
    answer: ["ひじき"],
    category: "speed",
    id: 6,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "「1」「8」「9」がそれぞれ3つ、5つ、3つに分解されています。解答のポイントは独立した数字ではなく、「189」としてみることです。「ひゃくはちじゅうきゅう」と読み、赤い部分の順番にある文字を読んでいくと、答えは「ひじき」になります。",
  },
  {
    answer: ["とまと", "トマト"],
    category: "speed",
    id: 7,
    score: 0,
    alcoholLevel: 0,
    problemMetaCategory: "onlyPerformance",
    explanation:
      "卓上のベルを鳴らしてみましょう。店員が「ちょっとまってくださーーい！」と答えます。丸数字の順に文字を読んで、答えは「トマト」です。",
  },
  {
    answer: ["しらす", "シラス"],
    category: "speed",
    id: 8,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図はそれぞれ「しらふ」「でいすい」のときの視界を表しています。丸数字の順に文字を読んで、答えは「しらす」です。",
  },
  {
    answer: ["ポテトフライ", "ぽてとふらい"],
    category: "speed",
    id: 9,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図はそれぞれ「ポイ」を2分割したもの、「寺（てら）」を2分割したもの、「豆腐（とうふ）」を3分割したものです。図の順通りに文字を読むと、答えは「ポテトフライ」になります。",
  },
  {
    answer: ["えだまめ", "枝豆"],
    category: "speed",
    id: 10,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図は「AMEDAMA」を表しています（同じアルファベットの部分は同じ色になっています）。矢印の順序通りに読んでいくと、答えは「EDAMAME」、すなわち「枝豆」になります。",
  },
  {
    answer: ["てんぷら"],
    category: "food",
    id: 101,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "送信しようとしているメールに「ら」が「添付」されています。よって、答えは「添付・ら」＝「てんぷら」です。",
  },
  {
    answer: ["あいなめ"],
    category: "food",
    id: 102,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図はそれぞれシュレディンガーの猫、魚と蛇を表しています。シュレディンガーの猫は、存在が「有るか無い」、魚と蛇は「歩かない」ということでどちらも「あるかない」となり、答えは「あいなめ」です。",
  },
  {
    answer: ["おでん"],
    category: "food",
    id: 103,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図は左から順に「O」「D」、そして色の違う灰色の丸は「EN（円）」を表します。よって、答えは「おでん」です。",
  },
  {
    answer: ["キムチ", "きむち"],
    category: "food",
    id: 104,
    score: 0,
    alcoholLevel: 0,
    explanation: "図は「無知」な「木」です。答えは「キムチ」です。",
  },
  {
    answer: ["さしみ", "刺身"],
    category: "food",
    id: 105,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "左側の図は上から順に砂糖、塩、酢です。それぞれ「AMAMI（甘味）」「ENMI（塩味）」「SANMI（酸味）」を表します（同じアルファベットの部分は同じ色になっています）。よって、答えは「さしみ（SASIMI）」です。",
  },
  {
    answer: ["うに", "雲丹"],
    category: "food",
    id: 106,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "上の図をみてみましょう。一見分数にみえるこの式は、「26（にじゅうろく）の1文字目、6文字目」を表しており、答えは「にく」になりました。下の図に同じルールを当てはめれば、「26（にじゅうろく）の4文字目、1文字目」となり、答えは「うに」となります。",
  },
  {
    answer: ["とろ", "トロ"],
    category: "food",
    id: 107,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図全体は「点」という文字の筆順を表しています（最後の4つの点が「灬」（れっか・れんが）を表しているのがポイントです）。「点」の上の部分、「占」を1・2画目、3・4画目で分解すると「ト」「ロ」となり、答えは「トロ」です。",
  },
  {
    answer: ["にんにく", "ニンニク"],
    category: "food",
    id: 108,
    score: 0,
    alcoholLevel: 0,
    problemMetaCategory: "onlyPerformance",
    explanation:
      "会場で同じ見た目の角瓶を探してみましょう。中の匂いを嗅いでみるとにんにくの匂いがしましたね。よって、答えは「にんにく」です。",
  },
  {
    answer: ["馬刺し", "ばさし"],
    category: "food",
    id: 109,
    score: 0,
    problemMetaCategory: "onlyPerformance",
    alcoholLevel: 0,
    explanation:
      "図は「謎解き居酒屋桐蔵」の公演ロゴを表しています。数字の部分の文字を読むと、答えは「ばさし（馬刺し）」になります。",
  },
  {
    answer: ["ほっけ", "ホッケ"],
    category: "food",
    id: 110,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "上の図の「サッカー」から「ー」を引くと「さっか（作家）」になります。下の図も「ホッケー」ー「ー」となり、答えは「ホッケ」です。",
  },
  {
    answer: ["たれかつ", "タレカツ"],
    category: "food",
    id: 111,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図のボタンの配置、ボタンが光っている様子から、エレベーターのボタンを連想できますね。「4=6」という表記から4文字目と6文字目が同じであることがわかります。1〜6に「エレベーター」という文字を当てはめ、光っている部分の文字を入れて読むと答えは「タレカツ」になります。",
  },
  {
    answer: ["やきとり", "焼き鳥"],
    category: "food",
    id: 112,
    score: 0,
    problemMetaCategory: "onlyPerformance",
    alcoholLevel: 0,
    explanation:
      "会場で同じ見た目のビンを探してみましょう。鏡月のビンに貼ってあるラベルを参考に、丸数字の部分の文字を順に読むと答えは「やきとり（焼き鳥）」になります。",
  },
  {
    answer: ["ゆば", "湯葉"],
    category: "recommend",
    id: 201,
    score: 0,
    problemMetaCategory: "onlyPerformance",
    alcoholLevel: 0,
    explanation:
      "会場に貼ってある図のうち、「同じ色の星」をつなげると、カタカナで「ユ」「バ」になりました。答えは「湯葉」になります。",
  },
  {
    answer: ["くえ", "クエ"],
    category: "recommend",
    id: 202,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "迷路は全部で7列あり、すでに埋まっている文字から「ローマ字で曜日を埋める」と推測できます。そのうえで迷路を解き、3文字目・8文字目・10文字目を読むと答えは「KUE（クエ）」になります。",
  },
  {
    answer: ["らむ", "ラム"],
    category: "recommend",
    id: 203,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "意味が通るように共通の文字を当てはめると、上から順に「こらむ」「らむね」「いすらむ」となります。よって、答えは「らむ（ラム）」です。",
  },
  {
    answer: ["あぶりしめさば"],
    category: "recommend",
    id: 204,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "矢印の方向に向けて読むとそれぞれ単語になるように、文字を入れていきましょう。すると、答えは「あぶりしめさば」となります（「さば」の箇所をバーナーで炙っているのがポイントです）。",
  },
  {
    answer: ["ひないじどり", "比内地鶏"],
    category: "recommend",
    id: 205,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図はそれぞれ「非ある他撮り」と「非ない自撮り」を表しています。答えは「比内地鶏」です。",
  },
  {
    answer: ["うどん"],
    category: "finish",
    id: 301,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図は「横断歩道」を表しています。白・黒それぞれに「おうだんほどう」という文字を当てはめ、数字の順に読むと答えは「うどん」となります。",
  },
  {
    answer: ["ちゃーはん"],
    category: "finish",
    id: 302,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "オレンジの四角には「ハン」、黄色の四角には「チャー」が入ります。「約2倍」にすると皿に盛られた「チャーハン」になることから、答えは「半チャーハン」です。",
  },
  {
    answer: ["ほるもんうどん"],
    category: "finish",
    id: 303,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図はフリック入力の際の日本語入力画面を示しています。よくみると赤色・青色で「上」「下」と書いてありますね。数字の色に合わせて上向き・下向きにフリックした際に入力される文字を数字の順に並び替えて、答えは「ホルモンうどん」になります。",
  },
  {
    answer: ["みそらーめん"],
    category: "finish",
    id: 304,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "箸で掴まれた麺は五線譜を示しています。ト音記号が書かれていると仮定して読むと、左から順に「レから始まる麺」＝「冷麺」、「ソから始まる麺」＝「そうめん」、そして「ミソラから始まる麺」＝「味噌ラーメン」、すなわち答えは「味噌ラーメン」です。",
  },
  {
    answer: ["かまめし"],
    category: "finish",
    id: 305,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "文字数や図形の配色、「う」の表出具合などから全体が「浦島太郎」の物語を示しているとわかります。つまり、赤い四角＝「りゅうぐうじょう」、薄だいだい色の四角＝「うらしまたろう」、緑の四角＝「かめ」です。丸数字の順に読むと、答えは「かまめし（釜飯）」です。",
  },
  {
    answer: ["ばにら", "バニラ", "ばにらあいす"],
    category: "dessert",
    id: 401,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "文章に適切に語を当てはめると、「レバニラは レバーとニラを炒めた 中華料理」となり、答えは「バニラ」です。",
  },
  {
    answer: ["杏仁豆腐", "あんにんどうふ"],
    category: "dessert",
    id: 402,
    problemMetaCategory: "onlyPerformance",
    score: 0,
    alcoholLevel: 0,
    explanation:
      "会場で黒豆を探してみると、メニュー表に2つの黒豆がありました。黒豆を挟むように2つの黒豆の間に縦置きで箸を置くと、間にある文字は「杏仁豆腐」というふうに読めます。答えは「杏仁豆腐」です。",
  },
  {
    answer: ["じぇらーと"],
    category: "dessert",
    id: 403,
    score: 0,
    alcoholLevel: 0,
    explanation:
      "図は「Jアラート」によるメッセージを表しています。7分割、すなわち7文字で「ジェイアラート」となり、そのうち3・4文字目を飛ばして読むと答えは「ジェラート」になります。",
  },
  {
    answer: ["マッコリ", "まっこり"],
    category: "weak-alcohol",
    id: 501,
    score: 0,
    alcoholLevel: 6,
    explanation:
      "盤面を埋めるようにピースを当てはめると「このこたえはつかわなかったぴーす」と読め、すなわち使わなかったピース「まっこり」が答えになります。",
  },
  {
    answer: ["ハイボール", "はいぼーる"],
    category: "weak-alcohol",
    id: 502,
    score: 0,
    alcoholLevel: 9,
    explanation:
      "「はは」「たいこ」「えるぼー」を並び替えると「こたえははいぼーる」となり、すなわち答えは「ハイボール」です。",
  },
  {
    answer: ["シャンディーガフ", "しゃんでぃーがふ"],
    category: "weak-alcohol",
    id: 503,
    score: 0,
    alcoholLevel: 3,
    explanation:
      "もともと上は「オーシャンビュー」、下は「シーディーケース」という文字列であったと想像できます。「シャン」「ディ」が「フ」になっており、答えは「シャンディガフ」です。",
  },
  {
    answer: ["甘酒", "あまざけ"],
    category: "weak-alcohol",
    id: 504,
    score: 0,
    alcoholLevel: 0.5,
    explanation:
      "二字熟語が成立するように四角の中に文字を埋めましょう。「大甘・甘口・甘栗」「飲酒・酒乱・酒蔵」と読め、答えは「甘酒」になります。",
  },
  {
    answer: ["ジンジャーハイ", "じんじゃーはい"],
    category: "weak-alcohol",
    id: 505,
    score: 0,
    alcoholLevel: 7,
    explanation:
      "のじゃロリの口調を想像してみましょう。のじゃロリは語尾に「じゃ」がつくため、「この人、ネトゲはいじんじゃ！」と言っています。答えは「じんじゃーはい（ジンジャーハイ）」となります。",
  },
  {
    answer: ["ウーロンハイ", "うーろんはい"],
    category: "weak-alcohol",
    id: 506,
    score: 0,
    alcoholLevel: 7,
    explanation:
      "四角の色・形状から、四角にはカタカナで「ブラウン」「パープル」が入りそうです。しかし、そのまま読んでも単語として成立しませんでした。背景が黄色であることに着目し、2・3・6行目に「イエロー」という文字列があると推測して、矢印の順に読むと答えは「ウーロンハイ」となります。",
  },
  {
    answer: ["緑茶ハイ", "りょくちゃはい"],
    category: "weak-alcohol",
    id: 507,
    score: 0,
    alcoholLevel: 5,
    explanation:
      "四角の色に着目して、そのまま「緑」「茶」「灰」と読んでみましょう。答えは「緑茶ハイ」です。",
  },
  {
    answer: ["コークハイ", "こーくはい"],
    category: "weak-alcohol",
    id: 508,
    score: 0,
    alcoholLevel: 3,
    explanation:
      "まずは芭蕉の名句を参考に、①＝「こ」を導きます。また、①「こ」を反転して横にすると「い」のような形になることから、右側には「俳句（はいく）」が当てはまりそうです。すると、答えは「こーくはい（コークハイ）」になります。",
  },
  {
    answer: ["レモンサワー", "れもんさわー", "れもん", "レモン"],
    category: "weak-alcohol",
    id: 509,
    score: 0,

    alcoholLevel: 9,
    explanation:
      "枠内は26文字ですが、どうやらアルファベットではなさそうです。「あな」「パン」をひらがなで埋めて考えてみましょう。「ドレミの歌」に出てくるモチーフが順番に並んでいるものだと推測できます（ドーナツレモンみんな……）。すなわち「？」は「レモン」、答えは「レモンサワー」となります。",
  },
  {
    answer: ["ピーチサワー", "ぴーちさわー", "ぴーち", "ピーチ"],
    category: "weak-alcohol",
    id: 510,
    score: 0,
    alcoholLevel: 9,
    explanation:
      "図は「ビーチ（beach）」を表しています。5分割したうちの初めの一つが上下逆転していることから、「b」が上下逆転して「p」になり、「peach」を表しているとわかります。そのため、答えは「ピーチサワー」となります。",
  },
  {
    answer: ["ライムサワー", "らいむ", "ライム", "らいむさわー"],
    category: "weak-alcohol",
    id: 511,
    score: 0,
    alcoholLevel: 5,
    explanation:
      "赤い四角の中の「エ」と「貢」という文字から、それぞれの色の矢印のすぐ隣にある「カタカナに読める部分」を四角に入れればよいとわかります。答えは「ライムサワー」となります。",
  },
  {
    answer: ["みかんサワー", "みかんさわー"],
    category: "weak-alcohol",
    id: 512,
    score: 0,
    alcoholLevel: 7,
    explanation:
      "文字の配列を手がかりに、000のお通しのナゾを思い出してみましょう。設問文には同じ配列で「さあ、ウォーミングアップです。わかるかな？」と書いてありました。丸数字の順に読むと、答えは「みかんサワー」です。",
  },
  {
    answer: [
      "シークワーサーサワー",
      "シークワーサー",
      "しーくわーさー",
      "しーくわーさーさわー",
      "しーくゎーさー",
      "しーくゎーさーさわー",
      "シークヮーサー",
    ],
    category: "weak-alcohol",
    id: 513,
    score: 0,
    alcoholLevel: 7,
    explanation:
      "シーサーが「くゎー」と言っていることから、「？」は「シークヮーサー」であるとわかります。そのため、答えは「シークヮーサーサワー」となります。",
  },
  {
    answer: ["カシスオレンジ", "かしすおれんじ", "かしおれ", "カシオレ"],
    category: "weak-alcohol",
    id: 514,
    score: 0,
    alcoholLevel: 3,
    explanation:
      "黒丸の配列は手の指を表しています。上から順に「こゆび、くすりゆび……おやゆび」と入れていくと、「かしお」「れ」となり、答えは「カシオレ」です。",
  },
  {
    answer: ["キティ", "きてぃ"],
    category: "weak-alcohol",
    id: 515,
    score: 0,
    alcoholLevel: 5,
    explanation:
      "上の文字列は郵便番号を表しており、先頭の赤い四角には「〒」が入るとわかります。答えの赤い四角に「〒」を埋めていくと、「キ」「テ」と読めそうです。答えは「キティ」です。",
  },
  {
    answer: ["ファジーネーブル", "ふぁじーねーぶる"],
    category: "weak-alcohol",
    id: 516,
    score: 0,
    alcoholLevel: 3,
    explanation:
      "2つの文字列から法則性を考えてみましょう。実はこれらの文字列はひとかたまりではなく、4つに分解して読む必要がありました。たとえば上の「ミエフヌングリー」は「ミ／エフ／ヌ／ングリー」と分解できます。分解された単語は前からそれぞれ「音階」「アルファベット」「な行」、そして「虹の七色を英語（カタカナ）で書き、一番最後の文字を先頭に持ってきたもの」を表します。ここから「？」に入る文字列を推測すると、答えは「ファジーネーブル」となります。",
  },
  {
    answer: ["カルーアミルク", "かるーあみるく"],
    category: "weak-alcohol",
    id: 517,
    score: 0,
    alcoholLevel: 9,
    explanation:
      "図は一見マグカップに見えますが、実はそうではありません。飲み物を入れる部分の形状に加え、片方の持ち手（に見える部分）が落下していることから、図は缶と磁石を表していると推測できます。左側の缶には磁石がくっついていることから「スチールカン（缶）」が、右側の缶にはくっついていないことから「アルミカン（缶）」が入り、指示通りに並び替えると答えは「カルーアミルク」となります。",
  },
  {
    answer: ["レッドアイ", "れっどあい"],
    category: "weak-alcohol",
    id: 518,
    score: 0,
    alcoholLevel: 3,
    explanation:
      "まずは枠内に右から「11（eleven）」と埋めてみましょう。枠内に書いてあるプラス・マイナス付きの数字は、アルファベット順でその数字分だけ進めたり戻したりすることを表します。指示通りに操作し、枠内の文字を矢印の方向に読むと、答えは「redeye」、すなわち「レッドアイ」になります。",
  },
  {
    answer: ["ビール", "びーる", "beer"],
    category: "weak-alcohol",
    id: 519,
    score: 0,
    alcoholLevel: 9,
    explanation:
      "上の図はbe動詞とその人称による活用を示しており、左から順に「be」「am＝わたし」「are＝あなた」と埋めることができます。丸数字の順番に読むと答えは「beer」すなわち「ビール」になります。",
  },
  {
    answer: ["照葉樹林", "しょうようじゅりん"],
    category: "weak-alcohol",
    id: 520,
    score: 0,
    alcoholLevel: 9,
    explanation:
      "上の抹茶（まっちゃ）のイラストから推測すると、数字は2桁ごとに区切り「五十音順における順番」を示していると解釈できます。また、文字色が青色の部分は小書き文字（拗音や促音）を表しています。そのルールに基づいて下の数字列を解読すると、答えは「しょうようじゅりん（照葉樹林）」となります。",
  },
  {
    answer: ["梅酒", "うめしゅ"],
    category: "strong-alcohol",
    id: 601,
    score: 0,
    alcoholLevel: 15,
    explanation:
      "左側にはアルファベットが反転した文字がありますが、それぞれ「L（eru）の反転＝ure」「S（esu）の反転＝use」と解釈すれば、イラスト通り「うれしい」「させつ（左折）」と読むことができます。そのルールに基づいて一番下の文字列を読むと「Mしゅ＝umeしゅ＝うめしゅ」となり、答えは「梅酒」となります。",
  },
  {
    answer: ["ドンペリ", "どんぺり"],
    category: "strong-alcohol",
    id: 602,
    score: 0,
    alcoholLevel: 12.5,
    explanation:
      "灰色の矢印は「3（スリー）」「スペード」を指しています。丸の中にそれぞれの文字を埋めましょう。赤矢印の方向に読めば答えは「ドンペリ」になります。",
  },
  {
    answer: ["ワイン", "わいん", "wine"],
    category: "strong-alcohol",
    id: 603,
    score: 0,
    alcoholLevel: 12,
    explanation:
      "「1」「8」「9」がそれぞれ2つ、3つ、3つに分解されています。それぞれ「ワン」「エイト」「ナイン」と読めば数が合いそうです。赤い部分の順番にある文字を読んでいくと、答えは「ワイン」になります。",
  },
  {
    answer: ["モヒート", "もひーと"],
    category: "strong-alcohol",
    id: 604,
    score: 0,
    alcoholLevel: 10,
    explanation:
      "マッチ棒を右下の指示通りに動かしていけば、「モヒート」と読めそうな図形が出てきます。よって答えは「モヒート」です。",
  },
  {
    answer: ["泡盛", "あわもり"],
    category: "strong-alcohol",
    id: 605,
    score: 0,
    alcoholLevel: 30,
    explanation:
      "「皿」の上に分解された文字が置いてあります。「皿」という字も含めて組み合わせると、お酒の名前「泡盛」が出てきますね。よって答えは「泡盛」です。",
  },
  {
    answer: ["しゃんぱん", "シャンパン"],
    category: "strong-alcohol",
    id: 606,
    score: 0,
    alcoholLevel: 12.5,
    explanation:
      "右上のイラストが「はいしん」であることから、左の漢字を縦読みした「上海(しゃんはい)」「審判(しんぱん)」を矢印の順で読んだものだと分かります。答えは「シャンパン」です。",
  },
  {
    answer: ["ジン", "じん", "GIN", "gin"],
    category: "strong-alcohol",
    id: 607,
    score: 0,
    alcoholLevel: 10,
    explanation:
      "①②③ = TEA をうめると、時計回りに「MORNING」「AFTERNOON」「EVENING」「NIGHT」の循環を表していたことが分かります。④⑤⑥を読んで、答えは「ジン(GIN)」です。",
  },
  {
    answer: ["ブランデー", "ぶらんでー"],
    category: "strong-alcohol",
    id: 608,
    score: 0,
    alcoholLevel: 37,
    explanation:
      "「デート」が「ラブラブな、またはラブラブになりそうな二人が会って過ごすこと」を説明しているようです。対応する丸数字を読むと、答えは「ブランデー」になります。",
  },
  {
    answer: ["バーボン", "ばーぼん"],
    category: "strong-alcohol",
    id: 609,
    score: 0,
    alcoholLevel: 40,
    explanation:
      "上向き矢印の丸数字は「up」、下向き矢印の丸数字は「down」の何文字目かを表していました。たとえば、一番左の上下反対の②は、「up」の2文字目「p」が上下反対となるので「b」が？マークに入ります。また、真ん中にある下向き矢印の欠けた④は、「down」の4文字目「n」の右側が欠けて「r」を表していました。同様に全て変換すると、答えは「バーボン(bourbon)」となります。",
  },
  {
    answer: ["ウォッカ", "うぉっか"],
    category: "strong-alcohol",
    id: 610,
    score: 0,
    alcoholLevel: 40,
    explanation:
      "右側の◯は12個並んでおり、文字数から12星座を表していることが分かります。丸数字の順に読んで、答えは「ウォッカ」となります。",
  },
  {
    answer: ["スクリュードライバー", "すくりゅーどらいばー"],
    category: "strong-alcohol",
    id: 611,
    score: 0,
    alcoholLevel: 12,
    explanation:
      "左から「スクリュー」「ドライ」「バー」を表していました。答えは「スクリュードライバー」です。",
  },
  {
    answer: ["モスコミュール", "もすこみゅーる"],
    category: "strong-alcohol",
    id: 612,
    score: 0,
    alcoholLevel: 12,
    explanation:
      "顧客と上手く意思疎通が取れていない様子を見て「もうすこしコミュニケーションするべきじゃ」とコメントしていそうです。？を順に読んで、答えは「モスコミュール」となります。",
  },
  {
    answer: [
      "ソルティドッグ",
      "そるてぃどっぐ",
      "そるてぃーどっぐ",
      "ソルティードッグ",
    ],
    category: "strong-alcohol",
    id: 613,
    score: 0,
    alcoholLevel: 13,
    explanation: "塩っぽい犬ですね。答えは「ソルティドッグ」です。",
  },
  {
    answer: ["ジンバック", "じんばっく"],
    category: "strong-alcohol",
    id: 614,
    score: 0,
    alcoholLevel: 12,
    explanation:
      "前にいるインド人が「インドじんフロント」と言っているので、後ろにいるインド人は「インドじんバック」と言いそうです。答えは「ジンバック」となります。",
  },
  {
    answer: ["テキーラ", "てきーら"],
    category: "strong-alcohol",
    id: 615,
    score: 0,
    alcoholLevel: 50,
    explanation:
      "カタカナで「テキ」と書かれていたところから、カタカナの「ラ」が「テ」から差し引かれたようです。「テキー(引く)ラ」で、答えは「テキーラ」となります。",
  },
  {
    answer: ["スピリタス", "すぴりたす"],
    category: "strong-alcohol",
    id: 616,
    score: 0,
    alcoholLevel: 96,
    explanation:
      "煤(すす)の中に「パイ」というカタカナが入って「スパイス」を表しているようでふ。同様にスで始まってスで終わる酒を考えましょう。微かに見える「タ」も考慮すると、答えは「スピリタス」となります。",
  },
  {
    answer: ["日本酒", "にほんしゅ"],
    category: "strong-alcohol",
    id: 617,
    score: 0,
    alcoholLevel: 15,
    explanation:
      "「日本(にほん)」から本州以外の都道府県を引き、「ほぼ本州(ほぼほんしゅう)」となる様子を式であらわしているようです。答えは「日本酒(にほんしゅ)」となります。",
  },
  {
    answer: ["ハブ酒", "はぶしゅ", "ハブシュ"],
    category: "strong-alcohol",
    id: 618,
    score: 0,
    alcoholLevel: 35,
    explanation:
      "カタカナの「ハ」のとなりに「部首(ぶしゅ)」が並んでいました。続けて読んで、答えは「ハブ酒」となります。",
  },
  {
    answer: ["紹興酒", "しょうこうしゅ", "しょーこーしゅ"],
    category: "strong-alcohol",
    id: 619,
    score: 0,
    alcoholLevel: 35,
    explanation:
      "右の数字は「小学生」「中学生」「高校生」の年齢を表していました。ひらがなにしてあてはまるマスを読むと、答えは「紹興酒(しょうこうしゅ)」となります。",
  },
  {
    answer: ["ウイスキー", "ういすきー", "ウィスキー", "うぃすきー"],
    category: "strong-alcohol",
    id: 620,
    score: 0,
    alcoholLevel: 40,
    explanation:
      "雲のまわりには空があるので、「black cloud」のまわりに広がるのは「white sky」だと考えられます。「white sky」の一部を拾ってできる酒を予想すると、答えは「ウイスキー(whisky)」となります。",
  },
  {
    answer: ["ラム", "らむ"],
    category: "strong-alcohol",
    id: 621,
    score: 0,
    alcoholLevel: 40,
    explanation:
      "フードの203番に同じ配置の3つの？があるので、こちらと同じ答えだと考えられます。答えは「ラム」です。",
  },
  {
    answer: ["ジントニック", "じんとにっく"],
    category: "strong-alcohol",
    id: 622,
    score: 0,
    alcoholLevel: 14,
    explanation:
      "二人の名前を一緒に呼ぶと、「じんとニック」になりますね。答えは「ジントニック」です。",
  },
  {
    answer: ["焼酎", "しょうちゅう", "ショウチュウ"],
    category: "strong-alcohol",
    id: 623,
    score: 0,
    alcoholLevel: 25,
    explanation:
      "丸のサイズが大中小あり、上が「だいしょう(代償)」、真ん中が「ちゅうしょう(抽象)」を説明していました。答えは「しょうちゅう(焼酎)」となります。",
  },
  {
    answer: ["マリブコーク", "まりぶこーく"],
    category: "strong-alcohol",
    id: 624,
    score: 0,
    alcoholLevel: 9,
    explanation:
      "4つのアルファベットは4つの四季の頭文字を表しており、上から「Spring」「Summer」「Fall」「Winter」となります。丸数字の順に読んで、答えは「マリブコーク」となります。",
  },
  {
    answer: ["アマレット", "あまれっと"],
    category: "strong-alcohol",
    id: 625,
    score: 0,
    alcoholLevel: 24,
    explanation:
      "@には左からそれぞれ「ア」「ッ」「ト」「マ」「ー」「ク」が入ります。左下にあるカタカナの「レ」も読むと、答えは「アマレット」となります。",
  },
];

export const PROBLEM_DICT: {
  [problemID: ProblemID]: Omit<ProblemDefinition, "status">;
} = {};
PROBLEMS.forEach((problem) => {
  PROBLEM_DICT[problem.id] = problem;
});
