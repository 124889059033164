import { Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import { ReactNode } from "react";
import { ProblemPopupButton } from "src/components/pages/main/problem_popup_button";
export const MainPage = () => {
  return (
    <Fade in={true} style={{ width: "100%", height: "100%" }} timeout={1000}>
      <Box
        width={"100%"}
        height={"100%"}
        p={"5vw"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        className="space-5"
      >
        <p>
          この度は居酒屋桐蔵の持ち帰り謎をお買い上げいただき誠にありがとうございます！
        </p>
        <p>
          本公演をWeb上で再現した「すこああたっくモード」と、本公演の全ての謎を確認できる「一覧モード」の２つのモードをご用意しています。
        </p>
        <NaiveHeader>すこああたっく モード</NaiveHeader>
        <p>
          すこああたっくは、15分の制限時間内に何杯のお酒を飲めるかを競うモードです。
        </p>
        <p>
          お酒だけを飲み進めると酔いが回り、問題を解くことが難しくなります。
        </p>
        <p>
          適度に食事も取りつつお酒を楽しむことで、より多くの問題を解くことができるでしょう。
        </p>
        <NaiveHeader>一覧 モード</NaiveHeader>
        <p>
          一覧は、居酒屋桐蔵の全てのメニューを好きな順で解くことができるモードです。
        </p>
        <p>時間を気にせず、のんびりと当店の料理・お酒をお楽しみください。</p>
        <p>以下は問題の凡例となります。</p>
        <Box className="space-5">
          <Box>
            <ProblemPopupButton
              problemID={0}
              canPopup={false}
              status="unsolved"
            />
            - 未回答の問題
          </Box>
          <Box>
            <ProblemPopupButton
              problemID={0}
              canPopup={false}
              status="solved"
            />
            - 回答済みの問題
          </Box>
          <Box>
            <ProblemPopupButton
              problemID={0}
              canPopup={false}
              problemMetaCategory="onlyPerformance"
            />
            - 公演中のみ解答可能な問題
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

const NaiveHeader = (props: { children: ReactNode }) => {
  return (
    <h2
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        fontFamily: "Yuji Syuku, serif",
        fontSize: "1.2rem",
        fontWeight: "normal",
      }}
    >
      {props.children}
    </h2>
  );
};
