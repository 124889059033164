import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loginSwitch } from "src/store/recoil_state";

export type AuthTemplateProps = {
  children: React.ReactNode;
};

export const AuthTemplate = (props: AuthTemplateProps) => {
  const user = useRecoilValue(loginSwitch);
  const location = useLocation();

  // * this flag is used to catch the delay of login process.
  const [delayedNotLogInFlag, setDelayedNotLogInFlag] = useState(false);

  // dealing with delay of login process.
  useEffect(() => {
    let newID: NodeJS.Timeout | undefined;
    if (!user && !delayedNotLogInFlag) {
      // set new timeout to redirect to login page.
      newID = setTimeout(() => {
        setDelayedNotLogInFlag(true);
      }, 1500);
    }
    return () => {
      if (newID) {
        console.debug("clearTimeout in cleanup.", newID);
        clearTimeout(newID);
      }
    };
  }, [delayedNotLogInFlag, user]);

  if (delayedNotLogInFlag) {
    return <Navigate replace to={`/login${location.search}`} />;
  }

  if (!user) {
    return <div>now authenticating...</div>;
  } else {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          // backgroundImage: "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)), url(/images/background.jpg)",
          // backgroundImage: "url(/images/background.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          // backdropFilter: "blur(30px)",
          // WebkitBackdropFilter: "blur(30px)",
          backgroundAttachment: "fixed",
        }}
      >
        {props.children}
      </div>
    );
  }
};
