import { ProblemID } from "src/core/problem";
import { IzakayaRealTimeDataBaseData } from "src/core/type";
import { PROBLEMS } from "src/data/problem_data";

export type GameID = string;

export type TeamID = string;

export const drinkUpAlcohol = (
  gameID: GameID,
  customerID: number,
  drinkID: ProblemID,
  alcoholLevel: number
) => {
  console.log(gameID, customerID, drinkID, alcoholLevel);
};

export const generateInitialGameState =
  (): IzakayaRealTimeDataBaseData["game"][GameID] => {
    const game: IzakayaRealTimeDataBaseData["game"][GameID] = {
      status: {
        drunkennessLevel: 0,
        score: 0,
        progress: "playing",
      },
      order: [
        {
          alcohol: undefined,
        },
      ],
      config: {
        gameTimeMinutes: 15,
        memberAmount: 1,
        password: "password",
        startAt: Date.now(),
      },
      problem: {},
    };

    PROBLEMS.forEach((problem) => {
      game.problem[problem.id] = { ...problem, status: "unsolved" };
    });
    console.warn("initialGame", game);
    return game;
  };
