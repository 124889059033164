export type BaseImageProps = {
  src: string;
  aspectRatio?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
};

/**
 * when width === undefined and height === undefined, this image has width : 100%.
 */
export const BaseImage = (props: BaseImageProps) => {
  if (props.width === undefined && props.height === undefined) {
    return (
      <div
        style={{
          width: "100%",
          aspectRatio: props.aspectRatio,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={props.src}
          alt={"NoImage"}
          style={{
            objectFit: "contain",
            width: "100%",
            // height: "100%",
          }}
          // alt="title"
        />
      </div>
    );
  }

  if (props.width !== undefined) {
    return (
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <div
          style={{
            width: props.width,
            maxWidth: props.maxWidth,
            // height: props.height,
            aspectRatio: props.aspectRatio,
          }}
        >
          <img
            src={props.src}
            alt={"NoImage"}
            style={{
              objectFit: "contain",
              width: "100%",
            }}
          />
        </div>
      </div>
    );
  } else if (props.height !== undefined) {
    return (
      <div
        style={{
          height: props.height,
          aspectRatio: props.aspectRatio,
        }}
      >
        <img
          src={props.src}
          alt={"NoImage"}
          style={{
            objectFit: "contain",
            width: "100%",
            // height: "100%",
          }}
          // alt="title"
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          aspectRatio: props.aspectRatio,
        }}
      >
        <img
          src={props.src}
          alt={"NoImage"}
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
          // alt="title"
        />
      </div>
    );
  }
};
BaseImage.defaultProps = {
  aspectRatio: "16/9",
};
