import jsSHA from "jssha";

/**
 * This file is copied from scripts/hash_list.txt
 */
export const HASH_DATA = [
  "00abc77c113f616ec88ac523233f306da33ad9cbcd529b2b179e9c73ff4e05a5",
  "b17f18bbc836e5acc45246c0d06b13636540554460122bdfdb3b7dc17eefd6dd",
  "40f1738baec4cda6c62f7083909fe753a3b9f604bc2fba523c3b1042bffa8dc9",
  "2b68d26883d987e1997677b46f3a22c8314b302157094bb5e9838a044a9477c9",
  "a68d5b97a98132c5df9c44961cf1ba44426dc579775ea4765c60dfa275465672",
  "43dddaec52d8494dd3c37e0154c8829beedeb1c9b95eae13dfe9e80658774ea3",
  "6b157076e8496ba03b990141c75c019750c609713195fdba23f234696f33965e",
  "6f240d1c6e9823fe59814218aae8f28dbcaf125e4155949e1e10236b25f9e04a",
  "e212986a90dd8d9557caa83fb424cf3c1ff914cc42e6448c30d7749f05880d84",
  "df574bb15c1698761c2ea081f71f382c95d5c30af811c2d982cf80578bfec5d8",
  "d5699f6a4a7ed52635cca4b14c20144f152c96f68c8cc75422e67d5fe1ac63aa",
  "7478876011649bb8d02af46b0f15d21db8bea8de4b2ec1fcb7433afba3b6038c",
  "d1052b89bdd2fd6df9584e8191603b22599e9ac3c8ea7a53593468545cddbc57",
  "5c620c445d331c0596b983f12182943dfc382f2e6bf5d7d2ea078ff01f939955",
  "cd7a4ce99e23fb5c308f555592300cf62a1f82f4bddcdb55606cda5588eeac2e",
  "6ac70772a7e512bb72dce1eeea913b4b4474df6d6bd0d75c10623adaf10fc64d",
  "b5d3f59590182bede795fdde084e1b5ae51e37a8065dd154c2b059f1e7b6111b",
  "7c3e65f291ddfdc7211f4353aae167ec141bff826621067c5f1c4024041df30b",
  "84f4e58365c422aa86e738e05656fcede2d66189e37fec47e1a0ba5bdb229884",
  "250822479667aa63e9305b00715657a59083e5b0b380e24c58fbf5d1d5b4265d",
  "a9e95b86be6d5a1c600eab56c22bf8d82f69334e8915a9a87c7608fd1f1b752b",
  "847a55cc9bcd3df50c0b9e29bb0f3d3a1a4bdab9c075616b64499564faa1f9f6",
  "9e07e5f73f64c5201005bcdb6ff7b5a5027ad225d17518491d8e502ed45d0a68",
  "76cf345fe8dbe90ab31426e1cc1dfdeecb45afc5471032e149c213eab33bd0ab",
  "c12f735f64acb8b9b89fd994a66d39f3e8c4cb7a08d912b1c9e4266d551f6fd6",
  "263aabfaf9a85d322cc373f4300ca5c06fadd6ab93ecd4a8b4bdbba977936600",
  "c69caae22a2f00ee95c1c694cfcc8b3b559ec335836de914da5e2f8d3b3185d0",
  "6f1dc439083f7cddc597b9dd985f0e416a448ce0ad388a025833c18d4cf2af9f",
  "182832ad7b8c321d61f4f8776d106736c33bc145c928c38eabe7cde5d3970fe6",
  "efe9ef0e6deedc3cfdde4526ffcf420ca08630ba37a93715687e82c4bbe43255",
  "a5dc6cc225f1a877619c4cd1cfde34186882f435bf258bc76b72086ba3ed1d2a",
  "a69361fb7c4d7ecbaf74178cc85515e44d7c1d5b54ecc8c9fb02affb39af0e8b",
  "1c80d34d7180964e4fecf79ed2b038be2c04f44ed0c2963104ec6ab6781e4f7f",
  "9f2f7ecb46edd82dee13effef298d134bc5d9e4418643e9e782bda6cf757a0dd",
  "8870e24d1ca853149d645278e430c5f4a2d55ed519702ef846c440b60cbfa6d4",
  "b60866c34796d7ba75aed33fcd4907d2bb43bdfcbd64663bc38ab771adf83e5f",
  "b01966302767ba08b72491e70f153d8437b516b2ddcb599c4a19ba66dd67748a",
  "1429c9bb24deceb2591c67e7a6c1c8cb66e347f36b26d302935c9faa60fdcfca",
  "eca9fc90f5315ec2caec070e8364e8056f10a216b5484eb8f47a0be23275c416",
  "b07c6d60057842f21d0bab90b8f7c8dde53f7e93d93715768df5e62f6008b7e4",
  "594febdc6e1c854b67ec0534b00fb8c17ddc7cb47e2653c71216e6550fed310e",
  "4ccd357cfb12b68b03c92cec1d392f89b216f4abb4b5f2034600634bf91a9507",
  "c1aa96cfa07aed800d7c6b828f2663af1c608b7ee5c91db00de260d98e30c994",
  "1b2930962d34fd1cbf24ed2f6c104cf6ca415d79978a49fa6a7a1a1d75ea7e46",
  "407be533cfc95e49d4472a731ec05121e17bf826c378850f7a1a689a4ca2edc9",
  "14d91f912d07cdd44692783243836723745898d317fc433e5ebee4ff57ae86f5",
  "92210fdf542243ecc601e6b45f3cf5c4e8ba53d4df33f740442bc18a482501a5",
  "3de7f90471314a4be5ced02624390d6b99de5a6a5bc695abb230847e77670400",
  "b23c1fcb0b829079eecc52c12e60c28c7c5ae8fda55e4a6bf1a85eaa1313201c",
  "5a13c73bd2c9fbba5f35dc9ccf6a2dadac583dc0dc11af6893e1a48572093f87",
  "605971d1b01123aacb7ba4a6e1dfa2fbd7bdce1945decc67fd38c6ffbcd289d6",
  "89ea81096a872188c1b63db0cf857fbdb9242d0d48541657ae94a394dc703b8e",
  "5af59328dce75d002f4f4929269cede4f06116b106875c8823b385e176ab23a0",
  "337e2b201057fb6024a815453585e61d02676d2a585c7c0b26a21b4b1001a062",
  "c403bcd0e4c9f1aaaf1d573f1bc680234ce26cd97108910db83530cd1c000b97",
  "09ae265f6e89fa523ab9982670cb0c45b9bdd1c1831ceb02a5be7890a67c5921",
  "3e30c33188fddb5e714c75fa650a4ba024919aa7bc1dc0686cccd3965bb7afdf",
  "57f222c49daf8ccf12514da6deb5f647ae3a47665ac884d8fbd2891e227cb6a0",
  "fe009ffcc9cbc3fce1a50e52d7b1f0c16f6f9cb88748319fc92559f0711c40bd",
  "4f08c6ba622daf54c3b7d3a9cf7a7df7a6985a7928c716f05c949c6c2aa081c7",
  "31b51d197bd5e1de24906c005cc57a4de22ef007019bbb901993b0c0928bfe67",
  "b85b00e6ed4a408de8596c07972df8e02e3d88506a86c110782a29b0d6b1d240",
  "513c440dcf149aaa15d276a841c40096eda10ffb83979bed11dfca704ee8af62",
  "cf901cb49481a9c8c8fa80d50031ba8d54b5bc28a8b2bb9022e8c6899e27533a",
  "ab6a4c7cb625cb4e9c40a8a71116e64a29f28e0d61fe744fa95e101057d60ae7",
  "6d918bf30c306399a2473f111d8cafbf3ab64e73449300d0bae3f824bd3e90a0",
  "4c9ec403253c09164cadcc72e6bc7af6341b826ad8d966fa2664363ef5df7e42",
  "6e908bd8b2c8195f8306ba0815d11d42af2e83866707fda1f9fa1718126c3d02",
  "7890a7db440ac4fc0bf5f6e5b9410506edc2df1edd1973f7a2de4755b65cc430",
  "f8869780d411a5228f511122b9f9052502c0a93f7d7023a7daa44e6866f5e05e",
  "8892cc674b5be2d591c18569619c20ff157718d0367b079d41a975e964e65db7",
  "616aff4195f9f891cbf1a13b5d27b47b957f03a83ef15f467e974e07ad99d523",
  "2d72307252b767a09d88a35588aa9123336df30c3a640a321fa0eea42a376a7b",
  "09c01648f8dbdfe3ece503119b88cb68914a61e352d15b71876c06e6f069b515",
  "2faa20ca18239a44f6fc01609a98ed07d44cd04d7983caf486eb401f13db4dcb",
  "b05be4c8e98714cc2f37e9fe67bef2f43df6cda5929cf14155c66e0f69c47733",
  "4f5fd232c16aa860d3d622f63bf05713e8e4196b2bd01222c45c19a36045f39b",
  "76fab5995e4368b9581e7812bc453d825a9f787bd6c802bd337281a5c722eb0e",
  "91a8d1119f935c2d82ce3f06e98fcbf05e4e31cf97705964edcb9cc7f64e6abb",
  "c5440dfa726bee1d91b5ce03a500b7fcfbda2799d6b4b12bb3f6bceecf09bfcc",
  "e5eaa40b447ac9a4337e54a46fbda6c8fda369e0d3fc4cab25a1cfefb271e06c",
  "0b173d029f66c8edbf49a4eb389aadd5427c0f8daf54ee16aa3bd02b0c63507f",
  "5242e1cfb8ab1f88652ef0a89ad664f340d6f353b8ba7d1050b103f7e00bf14d",
  "6ecff84c87a9e26a2cfadcd9210dc5bfdb794e8f7d75893ceb99555d3eb98033",
  "d087f7ec453bdd17d1fc75fb33973ba1ef8b321df6bbadf9bc931b76ea064b9e",
  "0efabacdef5a33b58fb24cd7bf076cf14571b149742c9bad3519f03f87c5f3ea",
  "8935895c2925f63cc13ddb21461ba683fe67366c3f48500e022c707c1f84086c",
  "bd4f4d9ab09ade26e92a33d7d76b1cf8be54a77be916fa5992c915fad7fc16b2",
  "aa4f70b10c8b3e220d7853f61ee00e9abe1aa2e4faa20ed84f4e8119450e74ca",
  "fcc476212eb5c7dee71252b154fa2359c21b4291304a2114ff91dc467a8c9fc1",
  "804780d3d8186c1c15a822297878fb0a69c5372cf4965be202c206f2ccfbeaf7",
  "fe2f25e45026f4f9fa15c303844d9d0a6add09a12860b339b742b92f4a6dea21",
  "541c9e453b37344444a849ff71ebdb656eb7aeec8e5cb2cec21cca78559563fb",
  "6716d5c63e16bb39caebd9bfed30907fdab5faa9d61e1cfce155ebdb481181ec",
  "7e48daf9f44e5ac3e083b0c130e2c83265f46f5cefb8239f9035224b426e0b8a",
  "bd198b62c0dabdc8c383dfc3062bf0c455dc1f387fe1a207a229eb255d03d2f6",
  "a0565d95154d026db22ea238789f8e4916edda06a487e838450b560dc6758528",
  "158f03cac8eea0947fe9581bb2021b760319445db62b7cba0bfac6dce30a48af",
  "f3575cffeaa3ad1479600a6b1967d745476bcd8aaab1c0d855d0c7c108029ac8",
  "f0203b85e884eaf5072a2e4fdf83ac1ea827ae81f9a32c620ba753ccf022c8f7",
  "5a969c4e3b8408df3c383050c91b8dc46892875064778d9434fbd0ad754b80b0",
  "9f98bba60feca1a89f0b9ca1a617123dd881b6c856bbc6286212eb4d9e52e1f9",
  "e5e775205913159e249bc790cdbddc4f0a1e75cb2df12406aaeda8346d58779b",
  "d0d477a5bb023df1f365235cbcd53c30d5ef64d77576443493d834401313e5b7",
  "c317619dd7bc374187fd377149f33fa538cc9a25de09bbf94f0de45d5ab08b54",
  "5bcb7ef2ca83a85c5cb2026750bd4580c66c8c4a7ad2e9e93cb71dcc5db57273",
  "a4baba1da86a792d8823bcd0accf9d4986299e14f6ebae0a9eb15322d3a3e983",
  "a92baa9d78c97a378a0a4a069093c65b9e7e43868d6feb4577d917337fbac0a1",
  "ba1ae3dc4de087d27a504f64c20fa35c6b0c66b92d8fe77c69ab8182445e40c7",
  "49b055ddb74c6b908e88874ab7aeb7ba9aea29e8c1ed01289219126ff547eb06",
  "618ca41e77e63088823957e12262b57b642b4e070997e5663e3ccb5d367b9ab7",
  "3698ac8dcadd6a2f778fa77923fcf65f8be236c6db227f11a5203846c9c8c373",
  "99cd74aa9a6e38cba059fc54bb2c6c1a56b94c9cb9fa49f072549e27dd876d0b",
  "ce1d3aa91608645c01cfc2fc095dcf701a9dd6cb448086ae4c34346eb3775bbe",
  "40b244e05b5d55fd911b262b1e2cce6f66747098e7eda15ca280ca62e23a87f7",
  "097973968d94588236598891cf2d1b4f2e9df841b3dd1c9a2feb4424b04bbbb1",
  "6cda6d93f471fa6ca5ed9ad5d165e626df28b36d59149233333fb413d525ebbf",
  "059902a3c42937d8e5f1dda851e3a09e69686d5f5a7a1215b859cf66dc72c561",
  "691a2555340c01614c5d3748fbad4ed6e3b80090f4da99c8b913669b985d47bb",
  "a98a80535216b63cebc5a8b48c8b9b0e9209c592c86ba0db880353297a3fdda8",
  "0c1424b5151d8f1a6f230788dcbfcd2557c4947143c54af94817c968ff4c6d64",
  "0fb8d905393a22e16a0a5540dbcbe093b78b31607ca29e6eba567294c6436796",
  "23f13e9b91371927f373cca08e25eb259e6c91da8e9676d9cfbdde33598f95b5",
  "57af8fe7d6e310c87819d70335d8f5636bb75a25922ab50e3777a05ec47a26c5",
  "6815dd2f1a43db270ac01a70e852af844b052254accc03dd977d1542e5147cc1",
  "089549b042a4c88a7e2c52d9320fdd4fab956a99d0d53e6a60b67445f20c54d3",
  "ceec6edaa8a896ee88028fe895afed83eef4ca400e97f7566c47658e540bcb09",
  "92ab779e083edd0cf279c73fa21ce73b52b40966f8dae38e7633358eed23ae48",
  "fc508711ab842644e7d775cdf5554bbd3138dadaeb22cdcd0e3c4e7c598eef34",
  "a5ccb28855d2fa979ce75fa578f45f5dbed04c1387a7d0ee34207d7b6101f144",
  "0374648430701f3bd3c1d4ca1542b856c81d25d22770c94c5086b2fcc0577a40",
  "f1954e6267025788f80141abc3ea5ae06ddf65f3989d557b5adfd7f372d65a6f",
  "f143d6b096857ffa227c538785e2fdaefb72a01c2a54e1c0ca1af2d4cd4afe1a",
  "fecbc56fef83da299001a0665f80a4d907a67bbbe719eece6b84f0ec3cb9c73f",
  "a07719894be9fbfe68907e9701d0fa94e536f795078c1efba9d153babc6475e5",
  "2b50c24bc0ca57ba5864f9e05374867eba2ab78ea2dad96e9d1258665a0db294",
  "1fb69620962786c115147f56b234cf8a38e121c1053ba111a689665c6f42df0d",
  "4027fedbadea31a2e0c5d59b69c9714b568c14ab635c26968498e5ef2e5e8bc5",
  "862dd6b321cb266f7f23efc72e0c4f1d9e0ae5c57ee56a0112fcb7f388c21070",
  "73546fc2bca1df7ec61d9fb7e2524a0ac1bf79fa8013e2993459e5f74a3e3d0e",
  "6c2b3060f852f3418e8b0d4107b8baff9edc746b3742f6f5e485fe921b25330f",
  "06d374df40be93d3310a18ae4dbb9b484900c0b081bde3ceee87a964538c37c9",
  "cd3eabfa090bec7dab2326172d89c5be5f4aeaf609e561407c8838b992c7f009",
  "a8f4f4b5f1ac7267c5daaa18d9f8a871fbe0940d671edc8d918bd3bdedc56899",
  "1b989f36cdaea729cc990ff6bb5563dda7e5897b929803c31d32c053448ad373",
  "11014da640c68f8c550b3cbb7ce5faa5b11b9fd090f4198ba90d8f7920c6b7fc",
  "c978f30762a41ab82784959751999785aae9cc5d6968a89494f903679f6e1db9",
  "1f11f4f0b074e1433af4fcb9c1fb04d4b5c430a5919f713ce92933c2ec65a3eb",
  "7e4c94ea1049c3583b269be009beb23eed0e7513105546b88c2f59d9010d1c07",
  "8590accd371064e6daffe64aa8dd5f8f62f4a43d5eb868e0ca0db38436dbeef7",
];

export const calcHash = (token: string): string => {
  const shaNormal = new jsSHA("SHA-256", "TEXT");
  shaNormal.update(token);
  return shaNormal.getHash("HEX");
};
