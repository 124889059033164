import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useModal } from "src/components/organisms/context_modal/context_modal";
import { useNotifier } from "src/components/organisms/context_snackbar/context_snackbar";
import { useProblemCookie } from "src/core/problem";
import { PROBLEM_DICT } from "src/data/problem_data";

export type ProblemProps = {
  imagePath: string;
  problemID: number;
};

export const Problem = (props: ProblemProps) => {
  const [answer, setAnswer] = useState("");
  const [, setCookie] = useProblemCookie(props.problemID);
  const notifier = useNotifier();
  const modalController = useModal();

  return (
    <div
      style={{
        position: "relative",
        height: "80vh",
        width: "95vw",
        maxWidth: "1000px",
        display: "flex",
        padding: "10px",
        flexDirection: "column",
        overflow: "auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          height: 0,
          position: "relative",
          aspectRatio: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // maxWidth: calculateMaxWidth(),
        }}
      >
        <img
          style={{
            height: "100%",
            maxHeight: "95vw",
            aspectRatio: "1",
            // width: "100%",
          }}
          src={props.imagePath}
        />
      </div>
      <div
        style={{
          width: "100%",
          flexGrow: 0,
          position: "relative",
          display: "flex",
          alignItems: "center",
          height: "200px",
          flexDirection: "column",
          paddingBottom: "10px",
        }}
        className="space-5"
      >
        <Box display={"flex"} alignItems={"center"} width={"100%"}>
          <TextField
            label="回答"
            style={{
              width: 0,
              flexGrow: 1,
            }}
            value={answer}
            placeholder="ひらがなで回答"
            onChange={(event) => {
              setAnswer(event.target.value);
            }}
          />
          <Button
            style={{
              flexGrow: 0,
              height: "100%",
              width: "100px",
            }}
            variant="contained"
            onClick={() => {
              if (PROBLEM_DICT[props.problemID].answer.includes(answer)) {
                notifier.notifySuccess("正解です！");
                setCookie({
                  status: "solved",
                });
                modalController.close();
              } else {
                notifier.notifyError("不正解です...");
              }
            }}
          >
            回答
          </Button>
        </Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            解説
          </AccordionSummary>
          <AccordionDetails>
            {PROBLEM_DICT[props.problemID].explanation}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
